<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list
        nav
        dense
      >
        <template v-for="item in items">
          <v-subheader
            v-if="(item.heading && !item.superadmin) || (item.heading && item.superadmin && user.is_superadmin)"
            :key="item.heading"
            class="mt-4 grey--text text--darken-1"
          >
            {{ item.heading }}
          </v-subheader>
          <v-list-group
            v-else-if="(item.children && !item.superadmin) || (item.children && item.superadmin && user.is_superadmin)"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="item.to"
              @click="drawer = $vuetify.breakpoint.mdAndDown? false : drawer"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else-if="!item.superadmin || (item.superadmin && user.is_superadmin)"
            :key="item.text"
            color="primary"
            link
            :to="item.to"
            @click="drawer = $vuetify.breakpoint.mdAndDown? false : drawer"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 ma-2">
          <v-btn
            block
            rounded
            color="error"
            @click="logout"
          >
            <v-icon left>
              mdi-logout-variant
            </v-icon> Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="thingsnesia_base"
      dark
      elevate-on-scroll
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span>Thingsnesia</span>
      </v-toolbar-title>
      <!-- <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Search"
        class="hidden-sm-and-down"
      /> -->
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            <v-icon>{{ $vuetify.theme.dark? 'mdi-brightness-7' : 'mdi-brightness-4' }}</v-icon>
          </v-btn>
        </template>
        <span>Switch to {{ $vuetify.theme.dark? 'light' : 'dark' }} theme</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :style="{'display': installBtn}"
            v-on="on"
            @click="installer()"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Install Thingsnesia</span>
      </v-tooltip>

      <HeaderNotification />

      <v-menu
        v-model="profileMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            large
            v-on="on"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-img
                src="/img/avatar.png"
                alt="User"
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  src="/img/avatar.png"
                  alt="User"
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="user.is_superadmin">
                  <v-icon small>
                    mdi-shield-check
                  </v-icon> <span>Superadmin</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="user.is_organization_admin">
                  <span>Admin</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  User
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="error"
                      icon
                      v-on="on"
                      @click="userLogout"
                    >
                      <v-icon>mdi-logout</v-icon>
                    </v-btn>
                  </template>
                  <span>Logout</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-list>
            <v-list-item @click="goToProfile">
              <v-list-item-action>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-action>
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-content>
      <!-- page loading indicator -->
      <v-progress-linear
        v-if="pageLoading"
        indeterminate
        color="primary"
        class="mb-n1"
      />
      <!-- app version update snackbar -->
      <v-snackbar
        v-model="snackAppUpdate"
        :timeout="0"
        bottom
        right
      >
        Update is available!
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackAppUpdate = false"
          >
            Close
          </v-btn>
        </template>
        <v-spacer />
        <v-btn
          dark
          small
          color="primary"
          class="mt-2"
          @click.stop="refreshApp"
        >
          Refresh
        </v-btn>
      </v-snackbar>

      <v-container
        v-if="showComponent"
        fluid
        style="height:100%"
        class="pa-md-5 background_color"
      >
        <v-slide-y-transition>
          <v-alert
            v-if="!onLine"
            dense
            tile
            text
            color="error"
            border="left"
            class=""
          >
            <v-row
              no-gutters
              align="center"
            >
              <v-col class="grow">
                <strong>Error connecting to server.</strong> Check your network connection and try again.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  icon
                  @click="reloadComponent()"
                >
                  <v-icon>
                    mdi-cloud-refresh
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-slide-y-transition>

        <v-slide-x-transition
          leave-absolute
          hide-on-leave
        >
          <p
            :key="$pageTitle()"
            class="headline font-weight-bold mb-1"
          >
            {{ $pageTitle() }}
          </p>
        </v-slide-x-transition>
        <v-slide-x-transition
          leave-absolute
          hide-on-leave
        >
          <v-breadcrumbs
            :key="breadcrumbList[0].text"
            :items="breadcrumbList"
            class="pa-0"
          />
        </v-slide-x-transition>
        <v-divider class="my-3" />
        <transition name="fade">
          <router-view />
        </transition>
      </v-container>
    </v-content>

    <v-footer padless>
      <v-card
        tile
        width="100%"
        class="text-right"
      >
        <v-card-text>
          {{ new Date().getFullYear() }} — <strong>Thingsnesia</strong>. v{{ appVersion }}
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmptyObject } from '../../utils/utils';
import HeaderNotification from './HeaderNotification.vue';

export default {
  name: 'TheContainer',
  components: { HeaderNotification },
  data() {
    return {
      drawer: null,
      profileMenu: false,
      installBtn: 'none',
      installer: undefined,
      registration: null,
      snackAppUpdate: false,
      pageLoading: true,
      onLine: navigator.onLine,
      showBackOnline: false,
      dismissCountDown: 0,
      showComponent: true,
    };
  },

  computed: {
    ...mapGetters(['user']),

    appVersion() {
      return this.$store.getters.appVersion;
    },

    items() {
      return [
        this.organizationItem,
        { icon: 'mdi-monitor-dashboard', text: 'Dashboards', to: '/dashboards' },
        {
          icon: 'mdi-account-group', text: 'Users', to: '/users',
        },
        { heading: 'BILLING', superadmin: true },
        {
          icon: 'mdi-credit-card-outline', text: 'Subscriptions', to: '/subscriptions', superadmin: true,
        },
        {
          icon: 'mdi-cash', text: 'Plans', to: '/plans', superadmin: true,
        },
        { heading: 'DATA', superadmin: true },
        {
          icon: 'mdi-developer-board', text: 'Devices', to: '/devices',
        },
        {
          icon: 'mdi-link-box', text: 'Integrations', to: '/integrations', superadmin: true,
        },
        {
          icon: 'mdi-cog-sync', text: 'Triggers', to: '/triggers',
        },
        {
          icon: 'mdi-contactless-payment-circle', text: 'Commands', to: '/commands',
        },
        {
          icon: 'mdi-calendar', text: 'Schedules', to: '/schedules',
        },
        { heading: 'ACCOUNT' },
        { icon: 'mdi-account', text: 'Profile', to: '/profile' },
        { icon: 'mdi-bell', text: 'Notifications', to: '/notifications' },
        // {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'More',
        //   model: false,
        //   children: [
        //     { icon: 'mdi-wrench', text: 'Settings' },
        //     { icon: 'mdi-bell', text: 'Notifications' },
        //   ],
        // },
      ];
    },

    organizationItem() {
      return {
        icon: 'mdi-domain',
        text: this.user.is_superadmin ? 'Organizations' : 'My Organization',
        to: this.user.is_superadmin ? '/organizations' : `/organizations/${this.user.organization_id}`,
      };
    },

    breadcrumbList() {
      return this.$route.matched
        // delete container route
        .filter((r, index) => index !== 0)
        .map((route) => ({
          text: route.name,
          exact: true,
          disabled: !isEmptyObject(this.$route.query),
          to: route,
        }));
    },
  },

  created() {
    // listen for updated service worker file
    // if there is an update, show snackbar
    document.addEventListener(
      'swUpdated', this.showSnackAppUpdate, { once: true },
    );

    // intercept prompt install pwa
    // add button to install manually
    this.installPwa();

    // apply theme mode
    this.applyThemeMode();

    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      this.pageLoading = true;
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      this.pageLoading = false;
    });
  },

  mounted() {
    this.pageLoading = false;

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    ...mapActions(['userLogout']),
    logout() {
      this.userLogout();
    },

    goToProfile() {
      this.profileMenu = false;
      if (this.$route.name !== 'Profile') {
        this.$router.push({ path: '/profile' });
      }
    },

    installPwa() {
      let installPrompt;

      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        installPrompt = e;
        this.installBtn = 'block';
      });

      this.installer = () => {
        this.installBtn = 'none';
        installPrompt.prompt();
        installPrompt.userChoice.then((result) => {
          if (result.outcome === 'accepted') {
            console.log('Install accepted!');
          } else {
            console.log('Install denied!');
          }
        });
      };
    },

    applyThemeMode() {
      // auto dark/ligt mode base on system mode
      let mode;
      if (localStorage.getItem('darkMode')) {
      // manual
        mode = JSON.parse(localStorage.getItem('darkMode'));
        console.log('darkMode manual');
      } else {
      // auto
        mode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        console.log('darkMode auto');
      }
      this.$vuetify.theme.dark = mode || false;
    },

    showSnackAppUpdate(e) {
      this.registration = e.detail;
      this.snackAppUpdate = true;
    },

    refreshApp() {
      this.snackAppUpdate = false;
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage({ action: 'skipWaiting' });
    },

    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
      if (type === 'online') {
        this.reloadComponent();
      }
    },

    reloadComponent() {
      this.showComponent = false;
      this.$nextTick(() => {
        this.showComponent = true;
      });
    },
  },

  // mounted() {
  //   console.log(this.$vuetify.application.top);
  // },
};
</script>
