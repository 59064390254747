<template>
  <div>
    <v-btn
      ref="notificationButton"
      icon
      large
      title="Filter Parameter"
      @click="openNotification"
    >
      <v-badge
        v-if="unreadNotificationCount > 0"
        color="red"
        overlap
        :content="unreadNotificationCount"
      >
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-bell
      </v-icon>
    </v-btn>

    <v-menu
      v-model="menu"
      transition="scale-transition"
      :close-on-content-click="false"
      origin="top right"
      :activator="$refs.notificationButton"
    >
      <v-card
        width="400px"
        max-width="80vw"
        :loading="loading"
      >
        <v-card-title>
          Notifications
        </v-card-title>
        <v-divider />
        <v-list
          v-if="items.length"
          id="notification-list"
          three-line
          class="py-0"
        >
          <template v-for="(item, index) in items">
            <v-list-item
              :key="item.id"
              :class="[item.read_at? readColor : '']"
              @click="goToDetail(item)"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="'grey lighten-1 white--text'"
                  v-text="getNotificationIcon(item)"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <span class="text--secondary caption">{{ $options.filters.formatDateTime(item.created_at) }}</span>
                <v-list-item-title
                  :class="[!item.read_at? 'font-weight-bold' : '']"
                  v-text="item.payload.title"
                />
                <v-list-item-subtitle
                  class="text--primary"
                  :class="[!item.read_at? 'font-weight-bold' : '']"
                  v-text="item.payload.body"
                />
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="index + 1 < items.length"
              :key="index"
            />
          </template>
        </v-list>
        <div
          v-else
          class="d-flex justify-center ma-5"
        >
          No notifications available
        </div>
        <v-btn
          block
          text
          color="primary"
          @click="goToNotifications()"
        >
          View All Notifications
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Echo from '../../services/websocket';


export default {
  name: 'HeaderNotification',
  data() {
    return {
      menu: false,
      items: [],
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['user']),

    readColor() {
      return this.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3';
    },

    unreadNotificationCount() {
      return this.user?.unread_notifications_count ?? 0;
    },
  },

  watch: {
    menu(state) {
      if (state) this.getNotifications();
      // this.$store.dispatch('getProfile');
      this.getProfile();
    },
  },

  mounted() {
    this.subscribeNotification();
  },

  beforeDestroy() {
    // leave channel when logout
    Echo.leaveChannelPrivate(`App.User.${this.user.id}`);
  },

  methods: {
    ...mapActions(['getProfile']),
    async getNotifications() {
      this.loading = true;
      try {
        const response = await this.$api.getNotifications();
        this.items = response.data.data;
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Get Notifications');
      } finally {
        this.loading = false;
      }
    },

    goToNotifications() {
      this.menu = false;
      this.$router.push({ path: '/notifications' });
    },

    getNotificationIcon({ payload = {} }) {
      if (payload.type === 'device') return 'mdi-developer-board';
      return 'mdi-bell-alert-outline';
    },

    async readNotification(notificationId) {
      try {
        const read = this.items.find((item) => item.id === notificationId);
        if (read) read.read_at = new Date().toISOString();
        await this.$api.readNotification(notificationId);
        this.getProfile();
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Read Notification');
      }
    },

    goToDetail({
      id,
      payload: {
        type, typeable_id,
      } = {},
    }) {
      this.menu = false;
      this.readNotification(id);
      if (type === 'device') this.$router.push(`/devices/${typeable_id}`).catch(() => {});
      else this.$toast.warning('Can\'t fetch notification data');
    },

    openNotification() {
      if (!this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.menu = true;
        }, 0);
      } else {
        this.$router.push({ path: '/notifications' });
      }
    },

    subscribeNotification() {
      Echo.subscribeChannelPrivate(`App.User.${this.user.id}`)
        // listen to custom event, use . (dot) at the beginning
        .listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
          this.$toast.notification(data.payload.title);
          // add to notifications
          this.items.unshift(data);
          // re-count notification
          this.getProfile();
        });
    },
  },
};
</script>

<style scoped>
#notification-list{
    max-height: 50vh;
    overflow-y:auto;
    overflow-x:hidden;
    border-radius: 0;
}
</style>
